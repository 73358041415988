import { Directive, ElementRef, Input, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[frozenColStyle]',
  standalone: true
})
export class FrozenColStyleDirective {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}
  @Input({ required: true }) frozenColStyle: 'left' | 'right' = 'left';
  @Input() frozen = true
  
  private pseudoElement: any;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['frozen']) {
      if (this.frozen) {
        this.addPseudoElement();
      } else {
        this.removePseudoElement();
      }
    }
  }

  private addPseudoElement() {
    this.pseudoElement = this.renderer.createElement('div');
    this.renderer.setStyle(this.pseudoElement, 'content', '""');
    this.renderer.setStyle(this.pseudoElement, 'position', 'absolute');
    this.renderer.setStyle(this.pseudoElement, 'height', '100%');
    this.renderer.setStyle(this.pseudoElement, 'background-color', 'white');
    this.renderer.setStyle(this.pseudoElement, this.frozenColStyle === 'left' ? 'right' : 'left', '0');
    this.renderer.setStyle(this.pseudoElement, 'top', '0');
    this.renderer.setStyle(this.pseudoElement, 'border-left', `1px solid #091E4214`);
    this.renderer.setStyle(this.pseudoElement, 'pointer-events', 'none');

    this.renderer.appendChild(this.el.nativeElement, this.pseudoElement);
  }

  private removePseudoElement() {
    if (this.pseudoElement) {
      this.renderer.removeChild(this.el.nativeElement, this.pseudoElement);
      this.pseudoElement = null;
    }
  }
}
